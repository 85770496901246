import Column from "@/Components/Grid/Column";
import Container from "@/Components/Grid/Container";
import Row from "@/Components/Grid/Row";
import { Head, Link } from "@inertiajs/react";
import SygnetAA from '@static/aa-error.png?quality=85&w=320&format=webp&imagetools';

const Error = ({ status }: {
    status: number
}) => {

    const description = {
        503: 'Serwis niedostępny',
        500: 'Błąd wewnętrzny serwera',
        404: 'Strona nie została znaleziona',
        403: 'Brak dostępu',
        419: 'Sesja wygasła',
    }[status]


    return (
        <>
            <Head title={status.toLocaleString()}/>
            <div className="min-h-screen bg-gradientMain relative flex flex-col justify-center">
                <header className='maxMd:px-4 maxMd:pt-6 maxMd:pb-4 px-14 flex justify-between z-20 relative py-6 items-center maxMd:mb-6 mb-18'>
                    <Link href='/' title='Strona główna'>
                        <p className='text-midnightAbyss text-3xl font-bold tracking-widest'>LOGO</p>
                    </Link>
                </header>

                <main className='relative z-20 maxMd:mb-10 mt-auto'>
                    <Container>
                        <Row>
                            <Column xxl={4} xl={4} lg={6} md={6} className="mb-6">
                                <p className="uppercase text-accordorange text-[12px]">Błąd</p>
                                <h1 className="h1 text-midnightAbyss font-bold tracking-[1.2px]">{status}</h1>
                            </Column>
                        </Row>
                        <Row className="mb-16">
                            <Column xxl={4} xl={4} lg={6} md={6}>
                                <p className="h4 text-midnightAbyss tracking-[0.48px] mb-2">
                                    {description}
                                </p>
                                <p className="text-midnightAbyss text-base mb-6">Wróć do strony głównej, a jeśli problem będzie się powtarzał skontaktuj się z działem <Link href="/pomoc" className="text-accordorange">pomocy</Link>.</p>
                                <p className="text-midnightAbyss text-base">Możesz również skontaktować się z nami za pomocą adresu e-mail <a className="text-accordorange" href="mailto:pomoc@f2fdigital.pl">pomoc@f2fdigital.pl</a></p>
                            </Column>
                        </Row>
                        <Row className="justify-end mb-14">
                            <Column xl={3}>
                                <p className='text-midnightAbyss text-3xl font-bold tracking-widest'>LOGO</p>
                            </Column>
                        </Row>
                    </Container>
                </main>

                <footer className='px-14 maxMd:py-4 flex flex-wrap maxMd:gap-y-4 justify-center z-20 relative py-5 items-center gap-x-10 mt-auto'>
                    <a href='#' target='_blank' title='Polityka prywatności' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Polityka prywatności
                    </a>
                    <a href='#' target='_blank' title='Regulamin serwisu' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Regulamin serwisu
                    </a>
                    <Link href={route('front.help')} title='Pomoc' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Pomoc
                    </Link>
                </footer>
            </div>
        </>
    )
}

export default Error;